body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.bg-dark-blue {
  background-color: #1d1d4bf8;
}

.btn-black-text {
  color: black !important;
}

.btn-black-text:hover {
  color: black !important;
}

.cards-container {
  padding: 20px;
  border: 1px solid #ddd;
}

.search-input {
  width: 100%;
  margin: 0px 5%;
  margin-bottom: 20px;
  border: 1px solid #ddd;
}

.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0px 5%;
  margin-bottom: 20px;
}

.cards-list {
  gap: 5px;
  flex-wrap: wrap;
}

.card {
  border: 1px solid #ddd;
  padding: 20px;
  margin-bottom: 5px;
  border-radius: 10px;
  cursor: pointer;
  transition: transform 0.2s;
  width: 100%;
  will-change: transform;

  &:hover {
    transform: scale(1.05);
  }
}

.card.selected {
  background-color: #eee;
}

.add-card {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px dashed #ddd;
  padding: 20px;
  margin: 10px 5%;
  border-radius: 10px;
  cursor: pointer;
  transition: transform 0.2s;
  width: 100%;

  &:hover {
    transform: scale(1.05);
  }
}

.mantine-Stepper-content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow: auto;
}

.mantine-Tabs-root {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.mantine-Tabs-panel {
  flex-grow: 1;
  overflow: auto;
}

.ag-cell-edit-wrapper {
  display: flex;
  align-items: center;
  height: 100%;
}

.ag-cell-editor {
  width: 100%;
}
